.main-talk {
  margin: 10vh 15vw;
  border-left: none;
}

.talk-title {
  font-weight: 300;
  font-size: 70px;
  color: #423838;
  margin: 10px 0;
}

.talk-sub-title {
  font-weight: 300;
  font-size: 3em;
  color: #423838;
  margin-top: 0;
}

.talk-paragraph {
  margin: 5vh 0;
  font-size: 1.4em;
  line-height: 1.6em;
}

.talk-list {
  list-style: none;
}

.talk-list-item {
  font-size: 1.8em;
  line-height: 2em;
  margin: 2em 0;
  font-weight: 300;
  padding-left: 10px;
  border-left: 4px solid #bbd2c9;
}

.first-talk-container {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 5vw;
}

@media only screen and (max-width: 650px) {
  .main-talk {
    margin: 10vh 30px;
  }
  .first-talk-container {
    display: flex;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 480px) {
  .talk-title {
    font-size: 50px;
  }
  .talk-list {
    padding-inline-start: 0;
  }
}

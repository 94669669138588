.main-contact {
  margin: 10vh 10vw;
  border-left: none;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 5vw;
}

.contact-title {
  font-weight: 300;
  font-size: 70px;
  color: #423838;
  margin: 10px 0;
}

.contact-sub-title {
  font-weight: 300;
  font-size: 40px;
  color: #423838;
  margin: 10px 0;
}

.contact-paragraph {
  margin: 5vh 0;
  font-size: 1.4em;
  line-height: 2em;
}

.list {
  list-style: none;
}

.list > li {
  line-height: 2.5em;
  font-size: 1.5em;
}

@media only screen and (max-width: 992px) {
  .main-contact {
    grid-template-columns: 100%;
  }
}

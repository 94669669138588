body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.frontpage {
  display: flex;
  margin: 5vw;
  justify-content: space-around;
}

.name {
  font-size: 100px;
  display: flex;
  justify-content: center;
}

a:link {
  text-decoration: none;
  color: inherit;
}

.page-title {
  font-size: 70px;
  text-align: center;
  margin: 5vh;
  font-weight: 600;
}

.header {
  height: 75px;
  background-color: #04181f;
  color: white;
  width: 100vw;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 100vw;
}

.menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 60vw;
  max-width: 60vw;
  margin-right: 1vw;
}

.menu-choice {
  font-weight: 400;
}

.menu-choice :hover {
  color: white;
}

.ola {
  font-weight: 600;
  min-width: 38vh;
}

.contact-button {
  border: 2px solid #bbd2c9;
  padding: 0 1.5vw;
  border-radius: 40px;
  height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-button:hover {
  background-color: #bbd2c9;
}

.contact-button:hover > .cont {
  color: black;
}

h1,
h3 {
  margin: 0;
}

/* Contact footer */

.contact-footer {
  height: 30vh;
  width: 100vw;
  background-color: #364f57;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #dfe2e3;
}

.contact {
  margin: 0 5vw;
}

.contact-footer-title {
  font-weight: 350;
  font-size: 3em;
  margin-bottom: 15px;
  color: #dfe2e3;
}

.contact-footer-sub {
  margin: 10px 10px 10px 0;
  font-weight: 300;
  font-size: 1.2em;
  color: #dfe2e3;
}

.line {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

/* Author-footer */

.author-footer {
  height: 10vh;
  width: 100vw;
  background-color: #022531;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #ededf1;
}

.author {
  text-transform: none;
  font-weight: 200;
  margin: 0 5vw;
}

.link {
  text-decoration: "none";
  color: #ededf1;
}

/* BURGER STYLING */

/* Position and sizing of burger button */
.hamburger-container {
  display: none;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #105670;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #bbd2c9;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #105670;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #bbd2c9;
  border: 5px solid #bbd2c9;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow-y: hidden !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #bbd2c9;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  font-weight: 380;
  font-size: 1.1em;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 3em;
  outline: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
}

@media only screen and (max-width: 850px) {
  .menu {
    display: none;
  }
  .hamburger-container {
    display: block;
  }
}

.category-list {
  list-style-type: none;
  font-weight: 400;
  padding-left: 20px;
  color: #423838;
}

.list-item {
  cursor: pointer;
  margin: 10px;
  font-size: 1.4em;
  line-height: 2;
}

.chosen {
  font-weight: 500;
  font-size: 1.6em;
}

.container {
  display: flex;
  flex-direction: row;
  margin: 5vh 10vh;
}

.category {
  width: 20vw;
  margin: 10px 0;
}

.main {
  display: flex;
  flex-direction: column;
  border-left: 1px solid gray;
  padding-left: 5vw;
  width: 60vw;
}

.cartoon-description {
  margin: 5vh 0;
  font-size: 1.4em;
  line-height: 1.6em;
}

.cartoon-title {
  font-weight: 300;
  font-size: 70px;
  color: #423838;
  margin: 10px 0;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}


/* Responsivity */

@media only screen and (max-width: 1200px) {
  .container {
    flex-direction: column;
    margin: 0;
  }
  .list-item {
    display: inline;
  }
  .category-list {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: left;
    padding: 0;
    flex-wrap: wrap;
  }
  .category {
    width: 90vw;
    padding: 2vw 5vw;
    background-color: #becdd3;
    margin-top: 0;
  }
  .main {
    border-left: none;
    width: 80vw;
    justify-content: center;
    align-content: center;
    padding: 0;
    margin: 0 auto;
  }
  .cartoon-title {
    margin: 40px 0 0 0;
  }
}

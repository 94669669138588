.frontpage {
  background-color: #04181f;
  min-height: 100vh;
  margin: 0;
}

/* First section */

.main-section {
  height: 75vh;
  display: grid;
  grid-template-rows: 55% 45%;
  position: absolute;
  margin-top: 75px;
  top: 0;
  left: 0;
}

.green-square {
  position: absolute;
  top: 0;
  left: 8vw;
  width: 30vw;
  height: 80vh;
  background-color: #bbd2c9;
  z-index: 2;
}

.main-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 60vw;
  text-align: right;
}

.front-name {
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  font-size: 6.5vw;
  margin-bottom: 10px;
}

.occupation {
  color: white;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-weight: 300;
  font-size: 2.5vw;
}

.ola-photography {
  margin-left: 14vw;
  z-index: 10;
  max-width: 45vw;
  height: auto;
}

/* About me section */

.about-me {
  color: white;
  background-color: #2e4f5c;
  display: grid;
  grid-template-columns: 55% 45%;
  margin: 120vh 0 20vh 0;
}

.about-text {
  margin: 5vh 5vw 5vh 8vw;
}

.about-header {
  font-size: 65px;
  font-weight: 300;
  margin: 0;
}

.about-paragraph {
  font-size: 1.3em;
  line-height: 2em;
  font-weight: 300;
}

.drawing-container {
  background-color: white;
  display: flex;
  justify-content: center;
}

.ola-drawing {
  width: 450px;
  height: auto;
  margin: 10px;
}

/* Contact section */

.contact-section {
  width: 100vw;
  text-align: center;
  margin: 20vh 0 40vh 0;
  background-image: url("/pk_bakgrunn_opacity.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 610px;
  display: flex;
}

.contact-me-button {
  color: white;
  font-size: 50px;
  font-weight: 300;
  border: 4px solid #bbd2c9;
  border-radius: 100px;
  padding: 0.5em 0.8em;
}

.contact-me-button:hover {
  background-color: #bbd2c9;
  color: black;
  cursor: pointer;
}

@media only screen and (max-width: 2000px) {
  .main-header {
    margin-left: 50vw;
  }
  .front-name {
    font-size: 5vw;
  }

  .occupation {
    font-size: 2.2vw;
  }
}

@media only screen and (max-width: 1200px) {
  .front-name {
    font-size: 7vw;
  }

  .occupation {
    font-size: 2.8vw;
  }
}

@media only screen and (max-width: 1000px) {
  .drawing-container {
    display: none;
  }
  .ola-drawing {
    display: none;
  }
  .about-me {
    grid-template-columns: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .ola-photography {
    max-width: 70vw;
    height: auto;
  }
}

@media only screen and (max-width: 600px) {
  .green-square {
    display: none;
  }
  .ola-photography {
    width: 100vw;
    max-width: 100vw;
    height: auto;
    margin-left: 0;
  }
  .main-header {
    align-content: center;
    text-align: left;
    margin: 0;
    margin-left: 30px;
  }
  .front-name {
    font-size: 65px;
  }

  .occupation {
    font-size: 25px;
  }
  .main-section {
    margin-left: 0;
    margin-right: 0;
    width: 100vw;
  }
}

@media only screen and (max-width: 320px) {
  .front-name {
    font-size: 45px;
  }
  .occupation {
    font-size: 20px;
  }
}

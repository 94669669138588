.critic-list {
  list-style: none;
}

.critic-list-item {
  font-size: 1.8em;
  line-height: 2em;
  margin: 2em 0;
  font-weight: 300;
  padding-left: 10px;
  border-left: 4px solid #bbd2c9;
}

a {
  color: black;
}

a:hover {
  font-weight: 400;
}

@media only screen and (max-width: 480px) {
  .critic-list {
    padding-inline-start: 0;
  }
}

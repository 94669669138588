.preview-container {
  margin: 3vw auto;
  width: 600px;
  cursor: pointer;
}

.title,
.post-title {
  font-weight: 300;
  font-size: 55px;
  color: #423838;
  margin: 10px 0;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}

.post-title {
  font-size: 70px;
}

.company {
  font-weight: 600;
  font-size: 20px;
  color: #423838;
  margin: 10px 0;
}

.image-container {
  position: relative;
}

.image {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  width: 600px;
}

.preview-container:hover > .image-container > .image {
  opacity: 0.5;
}

.preview-container:hover > .title {
  color: black;
}

.grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 7vw;
  grid-template-columns: 50%;
  overflow-y: hidden;
}

.main-image {
  height: 91vh;
  margin: 0 auto;
}

.main-image-container {
  background-color: black;
  display: flex;
  justify-content: center;
  height: 91vh;
}

.main-content {
  margin: 10vh 15vw;
}

.post-description {
  margin: 10vh 0;
  font-size: 1.4em;
  line-height: 1.6em;
}

audio:hover,
audio:focus,
audio:active {
  -webkit-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  box-shadow: 15px 15px 20px rgba(0, 0, 0, 0.4);
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  transform: scale(1.05);
  outline: 0;
}

audio {
  -webkit-transition: all 04s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.4);
  display: block;
  width: 40vw;
}

.audio {
  margin: 10vh 0;
}

.resp-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%;
}

.resp-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* Fade in*/

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1200ms ease-out, transform 600ms ease-out,
    visibility 1200ms ease-out;
  will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

@media only screen and (max-width: 768px) {
  .image {
    width: 90vw;
  }
  .fade-in-section {
    margin: 50px 0;
  }
  .title {
    font-size: 40px;
  }
  .company {
    font-size: 15px;
  }
}

@media only screen and (max-width: 480px) {
  .main-image,
  .main-image-container {
    height: auto;
    width: 100%;
  }
  .main-content {
    margin: 10vh 15px;
  }
  .post-title {
    font-size: 45px;
  }
  .post-description {
    font-size: 20px;
    margin: 5vh 0;
  }
  audio {
    width: 80vw;
  }
}
